<template>
  <b-row v-if="evalHeadings.length > 0">
    {{ evalHeadings }}
    <b-col
      cols="12"
    >
      <b-card>
        <div style="margin-bottom: 15px">
          <b-button
            v-if="permission"
            variant="primary"
            class="btn-icon"
            :to="{ name: 'add-evaluations'}"
          >
            Edit Evaluation
          </b-button>
        </div>
        <b-table-lite
          responsive
          :items="evalHeadings"
          :fields="['Weighing', 'Concept']"
          class="HeightTableLite"
        >
          <template #cell(Weighing)="data">
            <div class="text-nowrap d-flex">
              <b-card-text
                class="font-weight-bold mb-25;"
                style="font-size: 10px;"
              >
                {{ `${data.item.TotalApplied} Agents only ${data.item.Aproved} approved  (${((data.item.Aproved * 100) / data.item.TotalApplied).toFixed(2)})` }} %
              </b-card-text>
            </div>
            <div class="progress-wrapper">
              <b-progress
                :value="(data.item.Aproved * 100) / data.item.TotalApplied"
                max="100"
              />
            </div>
          </template>
          <template #cell(concept)="data">
            <b-card-text
              style="font-size: 12px; width: 150px;"
            >
              {{ data.value }}
            </b-card-text>
          </template>
          <template #cell()="data">
            <b-card-text
              style="font-size: 12px; width: 450px;"
            >
              {{ data.value }}
            </b-card-text>
          </template>
        </b-table-lite>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTableLite, BCardText, BButton, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BProgress,
  },
  props: {
    evalHeadings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      permission: true,
      ValueProgress: [30, 50, 80, 90],
    }
  },
  mounted() {
    this.getPermission()
  },
  methods: {
    getPermission() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile !== 1) {
        this.permission = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.HeightTableLite{
  height: 200px  !important;
}

</style>
