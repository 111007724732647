<template>
  <b-card v-if="evalBasicInfo">
    <b-button
      v-if="permission"
      variant="primary"
      size="sm"
      class="btn-icon float-right"
      :to="{ name: 'add-evaluations'}"
    >
      Edit Evaluation
    </b-button>
    <h5 class="mb-2">
      {{ evalBasicInfo.Name }} 🧾
    </h5>
    <div>
      <div style="margin-left: 0px; margin-bottom: 8px; font-weight: 600;">
        Creation date: <b style="font-weight: 400 !important;">{{ evalBasicInfo.CreatedAt | date-format }}</b>
      </div>
      <div style="margin-bottom: 8px; font-weight: 600;">
        User: <b style="font-weight: 400 !important;">{{ evalBasicInfo.CreatedBy }}</b>
      </div>
      <div style="margin-bottom: 8px; font-weight: 600;">
        Category: <b style="font-weight: 400 !important;">{{ evalBasicInfo.Category }}</b>
      </div>
      <div style="margin-bottom: 8px; font-weight: 600;">
        Staff: <b
          v-for="staff in evalBasicInfo.Staff"
          :key="staff.Id"
          style="font-weight: 400 !important;"
        >{{ staff.Name }}, </b>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    evalBasicInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permission: true,
    }
  },
  mounted() {
    this.getPermission()
  },
  methods: {
    getPermission() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile !== 1) {
        this.permission = false
      }
    },
  },
}
</script>
