<template>
  <b-card
    v-if="evalBasicMetrics"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <h5>Stadistics</h5>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated at the moment
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(data, index) in evalBasicMetrics"
          :key="index"
          xl="3"
          sm="6"
          class="mb-1"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="getStyles(index).color"
              >
                <feather-icon
                  size="24"
                  :icon="getStyles(index).icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                v-if="index === 'Average'"
                class="font-weight-bolder mb-0"
              >
                {{ (data *= 100).toFixed(2) }} %
              </h4>
              <h4
                v-else-if="index === 'Average Duration'"
                class="font-weight-bolder mb-0"
              >
                {{ data | time-format }}
              </h4>
              <h4
                v-else
                class="font-weight-bolder mb-0"
              >
                {{ data }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ index }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardHeader, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  props: {
    evalBasicMetrics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getStyles(index) {
      switch (index) {
        case 'Average':
          return { color: 'light-primary', icon: 'TrendingUpIcon' }
        case 'Average Duration':
          return { color: 'light-warning', icon: 'ClockIcon' }
        case 'Agents that Applied':
          return { color: 'light-success', icon: 'UserIcon' }
        case 'Missing Agents':
          return { color: 'light-danger', icon: 'UserIcon' }
        default:
          return { color: 'light-primary', icon: 'TrendingUpIcon' }
      }
    },
  },
}
</script>
