<template>
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-input-group style="height: 38px;">
            <flat-pickr
              v-model="rangeDate"
              class="form-control mb-1"
              :config="{ mode: 'range', dateFormat: 'Y-m-d' }"
            />
            <b-input-group-append style="height: 38px;">
              <b-button
                variant="outline-primary"
                class="fs-8"
                size="sm"
                @click="updateData('this_week')"
              >
                This Week
              </b-button>
              <b-button
                variant="outline-primary"
                class="fs-8"
                size="sm"
                @click="updateData('this_month')"
              >
                This Month
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="handleSearch"
            />
            <v-select
              v-model="TypeStaffValue"
              :options="TypeofStaff"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select staff"
              :clearable="true"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableAgents"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      style="position: relative !important;"
      @sort-changed="onSortChange"
    >
      <template #head(Name)>
        <feather-icon
          icon="UserIcon"
          class="mx-auto"
          style="margin-right: 10px !important;"
        />
        Agent Name
      </template>

      <template #head(Score)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
          style="margin-right: 10px !important;"
        />
        Score
      </template>

      <template #head(Duration)>
        <feather-icon
          icon="ClockIcon"
          class="mx-auto"
          style="margin-right: 10px !important;"
        />
        Average Time of Evaluation
      </template>
      <!-- Column: Patient -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              class="border BAvatarPatientProfiles"
              size="32"
              :text="avatarText(`${data.value}`)"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ `${data.value}` }}
          </span>
          <small class="text-muted">Agent</small>
        </b-media>
      </template>

      <!-- Column: PhoneNumber -->
      <template #cell(Score)="data">
        <b-media
          v-if="data.value"
          vertical-align="center"
        >
          <template #aside>
            <b-avatar
              size="32"
              variant="light-success"
            >
              <feather-icon
                icon="TrendingUpIcon"
              />
            </b-avatar>
          </template>
          <span
            class="font-weight-bold d-block text-nowrap"
            style="margin-top: 5px;"
          >
            {{ (data.value *= 100).toFixed(2) }} %
          </span>
        </b-media>
        <b-media
          v-else
          vertical-align="center"
        >
          <span
            class="font-weight-bold d-block text-nowrap"
            style="margin-top: 5px;"
          >
            No Applied yet
          </span>
        </b-media>
      </template>

      <template #cell(Duration)="data">
        <span
          v-if="data.value"
          class="text-nowrap font-weight-bold d-block"
        >
          {{ data.value | time-format }}
        </span>
        <span
          v-else
          class="text-nowrap font-weight-bold d-block"
        >
          No Applied yet
        </span>
      </template>

      <template #cell(Count)="data">
        <span
          v-if="data.value"
          class="text-nowrap font-weight-bold d-block"
        >
          {{ data.value }}
        </span>
        <span
          v-else
          class="text-nowrap font-weight-bold d-block"
        >
          0
        </span>
      </template>
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.IdUser}-send-icon`"
            v-b-toggle.sidebar-add-new-profile
            icon="FileTextIcon"
            class="cursor-pointer"
            size="16"
            @click="applyEvaluation(data.item.IdUser, data.item.IdEvaluation || data.item.IdEvaluations)"
          />
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BMedia, BAvatar, BPagination, BTable, BFormInput, BButton, BInputGroup, BInputGroupAppend, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import evaluationsService from '@core/services/evaluations/evaluationService'
import staffService from '@core/services/staff/staffServices'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
// import profileService from '@core/services/profiles/profileService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BPagination,
    BTable,
    vSelect,
    BFormInput,
    BButton,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-toggle': VBToggle,

  },
  props: {
    dataInfoBasic: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      items: [],
      fields: [
        { key: 'Name', label: 'Agent Name', sortable: true },
        { key: 'Score', label: 'Average Score', sortable: true },
        { key: 'Duration', sortable: true },
        { key: 'Count', label: 'Times applied', sortable: true },
        { key: 'actions' },
      ],
      TypeStaffValue: [],
      TypeofStaff: [],
      type: '0',
      perPage: 5,
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'Name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      rangeDate: null,
      date1: '',
      date2: '',
    }
  },
  watch: {
    perPage() {
      this.getProfiles()
    },
    TypeStaffValue(val) {
      if (val === null) {
        this.type = '0'
      } else {
        this.type = val.Id.toString()
      }
      this.actualpage = 1
      this.dataInfoBasic(this.date1, this.date2, this.type)
      this.getProfiles()
    },
    /* eslint-disable */
    rangeDate(val) {
      if (val.split('to').length > 1) {
        const date = val.split('to')
        this.date1 = moment(date[0].trim()).format('YYYY-MM-DD')
        this.date2 = moment(date[1].trim()).format('YYYY-MM-DD')
        this.dataInfoBasic(this.date1, this.date2, this.type)
        this.getProfiles()
      }
    },
    /* eslint-enable */
  },
  created() {
    this.getProfiles()
    this.getAllStaffs()
    this.updateData('this_month')
  },
  methods: {
    getAllStaffs() {
      staffService.getAllStaff().then(response => {
        this.TypeofStaff = response.data.data
      })
    },
    getProfiles() {
      const idEvaluation = this.$store.getters['evaluations/getIdEvaluation']
      evaluationsService.getEvaluationUserMetrics({
        id: idEvaluation,
        page: this.actualpage,
        name: this.searchTerm,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        beginDate: this.date1,
        endDate: this.date2,
        staffId: this.type,
      }).then(response => {
        this.items = response.data.evalUserMetrics
        this.FullCountEntries = response.data.evalUserMetrics[0].full_count
        this.numPages = response.data.evalUserMetrics[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getProfiles()
    },
    applyEvaluation(idAgent, idEvaluation) {
      console.log(idAgent, idEvaluation)
      this.$store.commit('profiles/setIdProfile', idAgent) // agent to apply the evaluation
      this.$store.commit('evaluations/setIdEvaluationApply', idEvaluation) // the evaluation id to apply
      this.$router.push({ name: 'evaluation-apply' }) // the evaluation route
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getProfiles()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getProfiles()
    },
    updateData(time) {
      const weekStart = moment().startOf('isoWeek').format('YYYY-MM-DD')
      const weekEnd = moment().endOf('isoWeek').format('YYYY-MM-DD')
      const monthStart = moment().startOf('month').format('YYYY-MM-DD')
      const monthEnd = moment().endOf('month').format('YYYY-MM-DD')
      switch (time) {
        case 'this_week':
          this.rangeDate = `${weekStart} to ${weekEnd}`
          break
        case 'this_month':
          this.rangeDate = `${monthStart} to ${monthEnd}`
          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
