<template>
  <b-row v-if="concept">
    <b-col
      lg="12"
      md="12"
      cols="12"
    >
      <b-card
        body-class="pb-50"
      >
        <b-avatar
          size="30"
          class="mb-1"
          variant="light-danger"
        >
          <feather-icon
            size="15"
            icon="TrendingDownIcon"
          />
        </b-avatar>
        <h6>{{ "worst" }}</h6>
        <small>{{ concept.Concept }}</small>
        <h2 class="font-weight-bolder mb-1">
          {{ parseInt(concept['Average Score']).toFixed(2) }} %
        </h2>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  props: {
    concept: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>
