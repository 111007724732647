<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <evaluation-info
          :eval-basic-info="EvalBasicInfo"
        />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <evaluation-stadistics :eval-basic-metrics="EvalBasicMetrics" />
      </b-col>
    </b-row>
    <b-row>
      <!-- Table Card -->
      <b-col lg="8">
        <agent-table :data-info-basic="getEvaluationBasic" />
      </b-col>
      <!--/ Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="12"
        cols="12"
      >

        <evaluation-concepts :concept="Concepts" />

        <evaluation-structure
          :eval-headings="EvalHeadings || []"
        />
        <!-- Bar Chart - Orders -->

        <!--/ Bar Chart - Orders -->
        <!-- Table Card -->
        <!--/ Table Card -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import evaluationsService from '@core/services/evaluations/evaluationService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EvaluationInfo from './EvaluationInfo.vue'
import EvaluationStadistics from './EvaluationStadistics.vue'
import AgentTable from './AgentTable.vue'
import EvaluationConcepts from './EvaluationConcepts.vue'
import EvaluationStructure from './EvaluationStructure.vue'

export default {
  components: {
    BRow,
    BCol,
    EvaluationInfo,
    EvaluationStadistics,
    AgentTable,
    EvaluationConcepts,
    EvaluationStructure,
  },
  data() {
    return {
      EvalBasicInfo: {},
      EvalBasicMetrics: {},
      EvalHeadings: [],
      Concepts: {},
      TableInfo: [],
      idEval: 0,
    }
  },
  methods: {
    /* eslint-disable */
    getEvaluationBasic(date1, date2, staff) {
      this.$store.commit('evaluations/setIdEvaluationApplytoEdit', null)
      const idEvaluation = this.$store.getters['evaluations/getIdEvaluation'] // TODO: SAVE VUEX
      evaluationsService.getEvaluationBasicInfo({
        id: idEvaluation,
        beginDate: date1,
        endDate: date2,
        idStaff: staff,
      }).then(response => {
        this.EvalBasicInfo = response.data.evalBasicInfo
        this.EvalBasicMetrics = response.data.evalBasicMetrics
        if (typeof(response.data.evalHeadings) !== 'string') {
          this.EvalHeadings = response.data.evalHeadings
        } else {
          this.EvalHeadings = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: `${response.data.evalHeadings}`,
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        }
        this.Concepts = response.data.evalBestWorstMetrics.worst
      })
    },
    /* eslint-enable */
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
